





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  ref,
  defineComponent,
  computed,
  Ref,
  watch,
  watchEffect,
  onUnmounted,
  onMounted
} from '@vue/composition-api';
import { StudentStatus, getStudentStatus } from '@/views/Monitor/studentStatus';
import type { OperationModeType } from '@/@types/operationMode.d';
import { OperationMode } from '@/constants/operationMode';
import _ from 'lodash';
import store, { useUserActions, useUserGetters, useUserState } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import moment from 'moment';
import axios from 'axios';
import { VERSION_TABLE_HEADER } from '@/components/molecules/const';
import { ITimeLineItem } from '../Guide.vue';

export default defineComponent({
  name: 'GuideBar',
  components: {
    MDraggableAdks: () => import('@/components/molecules/m-draggable-adks.vue'),
    MOrganizer: () => import('@/components/molecules/m-organizers.vue'),
    MActivitySchema: () => import('@/components/molecules/settings/m-activity-schema.vue'),
    MSettings: () => import('@/components/molecules/settings/m-settings.vue'),
    MActivitySettings: () =>
      import('@/components/molecules/activity-settings/m-activity-settings.vue'),
    SetupWizard: () => import('./SetupWizard/SetupWizard.vue'),
    MUseToken: () => import('@/components/molecules/m-use-token.vue'),
    SaveDocumentDialog: () => import('@/components/molecules/m-save-document-dialog.vue'),
    MMakeProgramPublic: () => import('@/components/molecules/m-make-program-public.vue')
  },
  props: {
    value: {
      required: true,
      type: Number
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    isVisitor: {
      type: Boolean,
      default: false
    },
    isNotAuthenticated: {
      type: Boolean,
      default: false
    },
    isSaveProgress: {
      type: Boolean,
      default: false
    },
    needsetup: {
      required: true,
      type: Array
    },
    title: {
      required: true,
      type: String,
      default: 'Employer Name'
    },
    operationMode: {
      required: true,
      type: Number as () => OperationModeType
    },
    studentDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    programDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    studentStatus: {
      required: false,
      default: null
    },
    adksWithPreviewMode: {
      required: false,
      type: Array,
      default() {
        return [];
      }
    },
    previewEnabled: {
      required: false,
      type: Boolean,
      default: false
    },
    canPublishProgram: {
      required: false,
      type: Boolean,
      default: false
    },
    userType: {
      required: true,
      type: String
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    isDraft: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props: any, ctx) {
    const useToken = ref();
    const { user }: any = useUserState(['user']);
    const headers = ref(VERSION_TABLE_HEADER);
    const selectedItem = ref('project');
    const deleteDialogue = ref(false);
    const deleting = ref(false);
    const itemTobeDeleted = ref();
    const deletionType = ref('');
    // const requiredAdks = ['start', 'results'];
    const requiredAdks = [];
    const items = ref([
      { id: 1, name: 'project', icon: 'mdi-lightbulb-on' },
      { id: 2, name: 'account', icon: 'mdi-account' },
      { id: 3, name: 'rocket', icon: 'mdi-rocket-outline' },
      { id: 4, name: 'feather', icon: 'mdi-feather' },
      { id: 5, name: 'comment', icon: 'mdi-comment-text-multiple-outline' },
      { id: 6, name: 'passenger', icon: 'mdi-elevator-passenger' },
      { id: 7, name: 'flash', icon: 'mdi-head-flash-outline' },
      { id: 8, name: 'timetable', icon: 'mdi-timetable' },
      { id: 9, name: 'bookmark', icon: 'mdi-bookmark-multiple' },
      { id: 10, name: 'teach', icon: 'mdi-teach' },
      { id: 11, name: 'supervisor', icon: 'mdi-account-supervisor-circle' },
      { id: 12, name: 'discord', icon: 'mdi-discord' },
      { id: 13, name: 'book', icon: 'mdi-book-open' },
      { id: 14, name: 'youtube', icon: 'mdi-youtube' },
      { id: 15, name: 'supervisor-outline', icon: 'mdi-account-supervisor-circle-outline' },
      { id: 16, name: 'file-account', icon: 'mdi-file-account' },
      { id: 17, name: 'star', icon: 'mdi-star' }
    ]);
    const hover = ref(false);
    const hoverIndex = ref();
    const isOptions = ref(false);
    const schemaIndex = ref('');
    const isSetupWizardOpen = ref(false);
    const dialogueType = ref();
    const {
      publishProgram,
      updateProgramTemplate,
      cloneProgram,
      revertVersion,
      saveDeliverableSetting,
      getSharableUrlByName,
      deleteAdkOrCategory,
      addCategory,
      editCategory,
      deleteDraft
    } = useUserActions([
      'publishProgram',
      'updateProgramTemplate',
      'cloneProgram',
      'revertVersion',
      'saveDeliverableSetting',
      'getSharableUrlByName',
      'deleteAdkOrCategory',
      'addCategory',
      'editCategory',
      'deleteDraft'
    ]);
    const { publishProgramRevision } = useUserActions(['publishProgramRevision']);
    const { getProgramRevision } = useUserActions(['getProgramRevision']);
    const { getUserByKeyAndValue } = useUserActions(['getUserByKeyAndValue']);

    const isSaveProgressValue: any = computed({
      get: () => props.isSaveProgress,
      set: newVal => {
        ctx.emit('isSaveProgressValue', newVal);
      }
    });
    const disableSaveProgress = ref(false);
    const categoryName = ref('');
    const activeCategory = ref('project');
    const categoryIcon = ref('');
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const expand = ref(window.innerWidth >= 991);
    const isAddCategory = ref(false);
    const showAdkAddIcon = ref(false);
    const closeBar = ref(window.innerWidth <= 991);
    const showPreInternshipSteps = ref(true);
    const showInternshipSteps = ref(true);
    const newOrganizerEmail = ref('');
    const validation: any = ref('');
    const inviteType = ref('Email');
    const phoneNumber = ref('');
    const formattedNumber = ref('');
    const dialogManageAccess = ref(false);
    const orgMsg = ref('');
    const isDraggable = ref(false);
    const isAddOptions = ref(false);
    const isCategoryDialogue = ref(false);
    const hiddenCategories = ref([]);
    const currentIndex = ref(0);
    const openProgramCheckoutView = ref(false);
    const openTemplateCheckoutView = ref(false);
    const openCheckoutView = ref(false);
    const isSaveChanges = ref(false);
    const sureLeaveProgram = ref(false);
    const isSavingChanges = ref(false);

    function openGuideBar() {
      expand.value = !expand.value;
    }
    const titleValue = computed({
      get: () => props.title,
      set: newVal => {
        ctx.emit('titleValue', newVal);
      }
    });
    const openActivitySchema = ref(false);

    const openActivitySchemaDialog = (index, category) => {
      ctx.emit('closeOverlay');
      schemaIndex.value = index;
      openActivitySchema.value = true;
      activeCategory.value = category;
    };

    const programDocValue = computed({
      get: () => props.programDoc,
      set: newPage => {
        ctx.emit('programDocValue', newPage);
      }
    });
    watch(
      () => props.programDoc,
      newVal => {
        programDocValue.value = newVal;
      },
      { deep: true }
    );
    const isPublished = computed({
      get: () => programDocValue?.value?.data?.published || false,
      set: newVal => {
        ctx.emit('isPublishedVal', newVal);
      }
    });
    const categories: any = computed(() => {
      const program = programDocValue?.value?.data?.categories;
      const sorted = program?.sort?.((a, b) => {
        if (a.name === 'project') {
          return -1;
        }
        if (b.name === 'signoff') {
          return 1;
        }
        return 0;
      });
      return sorted;
    });
    const timesheetIndex = ref(
      programDocValue.value?.data?.adks?.findIndex(adk => adk.name === 'timesheet')
    );
    const timesheetFieldIndex = ref(
      programDocValue.value?.data?.adks[timesheetIndex.value]?.fields?.findIndex(
        field => field.name === 'm-timesheet-default'
      )
    );
    const programIdValue: any = computed(() => {
      if (props.userType === 'participant') {
        return programDocValue.value.data.program_id.toString();
      }
      return programDocValue.value.data._id.toString();
    });

    const editTemplate = item => {
      const id = programIdValue?.value?.toString();
      let schemaType = props.isTemplate ? 'template' : 'program';
      if (props?.isDraft) {
        schemaType = 'ProgramRevision';
      }
      localStorage.setItem('redirectionPath', ctx.root.$route.path);
      ctx.root.$router.push({
        name: 'EditAdK',
        params: {
          id,
          schemaType,
          page: item.position
        },
        query: { type: 'edit', category: item.category }
      });
    };

    const addNewCategory = async () => {
      isAddCategory.value = true;
      let documentType = 'Program';
      if (props?.isTemplate) {
        documentType = 'ProgramSchema';
      } else if (props?.isDraft) {
        documentType = 'ProgramRevision';
      }
      const result = await addCategory({
        programOrTemplateId: programIdValue.value,
        categoryData: {
          name: categoryName?.value?.toLowerCase(),
          icon: selectedItem.value
        },
        documentType
      });
      if (!result.data.error) {
        await props.fetchProgram();
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: result.message,
          type: 'success',
          isShowSnackbar: true
        });
        isCategoryDialogue.value = false;
        isAddCategory.value = false;
        setTimeout(() => {
          categoryName.value = '';
          selectedItem.value = '';
          validation.value.reset();
        }, 500);
      } else {
        isCategoryDialogue.value = false;
        isAddCategory.value = false;
        setTimeout(() => {
          categoryName.value = '';
          selectedItem.value = '';
          validation.value.reset();
        }, 500);
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: result.data.error.description,
          type: 'error',
          isShowSnackbar: true
        });
      }
    };

    const projectItems = computed(() => {
      const timeline = programDocValue.value.data.adks.map(adk => ({
        ...adk,
        step: adk.name,
        tooltip: adk.status ? 'Go to activity' : 'Disabled',
        unlocked: true
      }));
      let timelineData;
      if (props.userType === 'participant') {
        timelineData = timeline
          ?.filter(item => item.status && item.category)
          .map(item => {
            if (
              item.isFieldAdded ||
              item.isFieldEdited ||
              item.isFieldDeleted ||
              item.isAdkDeleted ||
              item.isAdkAdded
            ) {
              return { ...item, tooltip: 'Program host made some edits in the ADK' };
            }
            return item;
          });
      } else {
        timelineData = timeline?.filter(item => item.fields);
      }
      return _.sortBy(timelineData, 'position') || [];
    });

    const internshipItems = computed(() => {
      const timeline = programDocValue.value.data.adks.map(adk => ({
        ...adk,
        step: adk.name,
        tooltip: adk.status ? 'Go to activity' : 'Disabled',
        unlocked: true
      }));
      const timelineData =
        timeline?.filter(item => item.status && item.category && item.category === 'internship') ||
        [];
      return _.sortBy(timelineData, 'position') || [];
    });

    const activeStep = computed({
      get: () => props.value,
      set: newPage => {
        ctx.emit('activeStep', newPage);
      }
    });

    const unlockedStep = computed(() => {
      let whichStep = 0;
      for (let i = props.timeline?.length - 1; i > 0; i -= 1) {
        if (props.timeline[i].unlocked) {
          whichStep = i;
          break;
        }
      }
      return whichStep;
    });

    const changeStep = (step: number, type: 'pre' | 'post') => {
      let newStep: ITimeLineItem | null = null;
      if (type === 'pre') {
        newStep = projectItems.value?.[step];
      } else if (type === 'post') {
        const internshipItemsStepIndex = step - projectItems.value.length;
        newStep = internshipItems.value?.[internshipItemsStepIndex];
      }

      const { operationMode } = props;
      if (operationMode === OperationMode.Setup && newStep?.unlocked) {
        activeStep.value = step;
      } else if (
        operationMode !== OperationMode.Setup &&
        (newStep?.unlocked ||
          (props.adksWithPreviewMode?.includes(newStep?.step ?? '') && props.previewEnabled))
      ) {
        activeStep.value = step;
      }
    };

    const toggleCategory = catName => {
      if (hiddenCategories.value.includes(catName)) {
        const index = hiddenCategories.value.indexOf(catName);
        hiddenCategories.value.splice(index, 1);
      } else {
        hiddenCategories.value.push(catName);
      }
    };
    const getColorOnBaseOfFlags = (
      isFieldAdded,
      isFieldEdited,
      isFieldDeleted,
      isAdkDeleted,
      isAdkAdded
    ) => {
      const green = '#6eba80';
      const red = '#ea6764';
      const blue = '#3c9dcd';
      if (isAdkDeleted || (!isAdkAdded && isFieldDeleted && !isFieldAdded && !isFieldEdited)) {
        return red;
      }
      if (
        (isAdkAdded && !isFieldAdded && !isFieldDeleted && !isFieldEdited) ||
        (isAdkAdded && isFieldAdded) ||
        (!isAdkAdded && isFieldAdded)
      ) {
        return green;
      }
      if (
        (isAdkAdded && !isFieldAdded && isFieldEdited) ||
        (!isAdkAdded && !isFieldAdded && isFieldEdited)
      ) {
        return blue;
      }
      return null;
    };

    const getIconColor = (adkIndex: number, adkObject: any) => {
      const green = '#6fba7f';
      const yellow = '#fdd35a';
      const gray = '#00000061';
      if (props?.isTemplate) {
        return gray;
      }
      const colorValue = getColorOnBaseOfFlags(
        !!adkObject?.isFieldAdded,
        !!adkObject?.isFieldEdited,
        !!adkObject?.isFieldDeleted,
        !!adkObject?.isAdkDeleted,
        !!adkObject?.isAdkAdded
      );
      if (colorValue) {
        return colorValue;
      }
      if (
        (adkObject.completed && !props?.isNotAuthenticated && props?.type !== 'public-program') ||
        (adkObject.completed && props.isNotAuthenticated && props?.type === 'public-program') ||
        (props?.userType === 'organizer' && !adkObject?.setupRequired) ||
        adkObject?.name === 'results'
      ) {
        return green;
      }
      // if (activeStep.value === adkIndex) {
      //   return yellow;
      // }
      return gray;
    };

    const getAdkCompletion = (adkObject: any) => {
      if (props?.isTemplate) {
        return false;
      }
      if (
        adkObject.completed ||
        (props?.userType === 'organizer' && !adkObject?.setupRequired) ||
        adkObject?.name === 'results'
      ) {
        return true;
      }
      return false;
    };

    const getCompletedIcon = () => {
      return '$complete';
    };

    const emitMolecule = (item, index) => {
      if (item.status && props.userType !== 'participant') {
        ctx.emit('click', { item, index });
      } else if (
        item.name === 'offer' &&
        item.status &&
        props.userType === 'participant' &&
        programDocValue?.value?.data?.offerDetails !== undefined &&
        item.completed
      ) {
        ctx.emit('click', { item, index });
      } else if (
        item.name !== 'offer' &&
        item.status &&
        props.userType === 'participant' &&
        item.completed
      ) {
        ctx.emit('click', { item, index });
      } else if (
        (props.adksWithPreviewMode.includes(item.name) && props.previewEnabled) ||
        item.name === 'start'
      ) {
        ctx.emit('click', { item, index });
      }
    };

    const isSetupAdksCompleted = computed(() => {
      const setupAdks = projectItems.value.filter(adk => adk.status && adk.setupRequired);
      const completedAdks = setupAdks.filter(adk => adk.completed);

      if (setupAdks.length === completedAdks.length) {
        return true;
      }
      return false;
    });

    const dialog = ref(false);

    const dialogOpen = ref(false);
    const dialog2 = ref(false);

    const programRevisionData = ref([]);
    const tableDialog = ref(false);
    const isLoading = ref(false);

    const progressText = ref('');
    const isSaving = ref(false);
    const dialogOpenForRevert = ref(false);
    const versionValue = ref('');
    const isShareAccess = ref(false);
    const openShareAccess = () => {
      isShareAccess.value = true;
    };
    const leaveProgram = async () => {
      isSavingChanges.value = true;
      try {
        const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
        const res = await axios.get(
          `${API_ENDPOINT}/leave-program/${programDocValue.value?.data?.program_id.toString()}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        const status = res?.data?.data;
        ctx.root.$router.push('/');
      } catch (error) {
        isSavingChanges.value = false;
        console.log(error);
      }
    };
    const openManageAccess = () => {
      dialogManageAccess.value = true;
    };

    const isDeliverableAvailable = computed(() => {
      return programDocValue.value?.adks?.find(a => a.name === 'deliverable');
    });

    /** Fetch program revision */
    const getProgramRevisionData = async () => {
      programRevisionData.value = [];
      tableDialog.value = true;
      isLoading.value = true;

      const response = await getProgramRevision({
        program_id: programDocValue.value.data._id.toString()
      });
      if (response) {
        programRevisionData.value = response.data;
        isLoading.value = false;
      }
    };

    function momentFormat(val) {
      const date = val.split(':');
      const versionInfo = date[0];
      const formatedDate = date[1];
      if (formatedDate === undefined) {
        return val;
      }
      const dateValue = `${versionInfo} ${moment(formatedDate).format('MMMM Do, YYYY @ h:mma')}`;
      return dateValue;
    }

    function formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss');
    }

    /** Publish unpublish program */
    const isSavingData = ref(false);
    const changePublishStatus = async () => {
      isSavingData.value = true;
      if (programDocValue.value.data.published === undefined && isDeliverableAvailable.value) {
        const data = {
          program_id: programDocValue.value?.data?._id.toString(),
          deliverableObj: {
            teamSettings: {
              required: true
            },
            projectPlanSettings: {
              required: true
            },
            projectTaskSettings: {
              required: true
            },
            caseStudySettings: {
              required: true
            },
            presentationSettings: {
              required: true
            },
            userInterviewSettings: {
              required: true
            },
            projectImplementationSettings: {
              required: true
            },
            digitalResumeSettings: true,
            projectFAQSettings: true
          }
        };
        const res = await saveDeliverableSetting(data);
      }
      const response = await publishProgram({
        program_id: programDocValue.value.data._id.toString(),
        published: !isPublished.value
      });
      await props.fetchProgram();
      programDocValue.value.data = {
        adks: [],
        ...getProgramDoc.value
      };
      dialog.value = false;
      isSavingData.value = false;
      dialogOpen.value = false;
      dialog2.value = true;
    };

    /** Save progress */
    const isSaveData = ref(false);
    const publishProgramRevisionData = async () => {
      isSaveData.value = true;
      if (progressText.value === '') {
        progressText.value = `Version created by ${user.value.firstName} ${user.value.lastName}`;
      }
      programDocValue.value.data.versionChanges = progressText.value;
      const response = await publishProgramRevision(programDocValue.value.data);
      if (response) {
        disableSaveProgress.value = true;
        isSaveData.value = false;
        dialogOpen.value = false;
        progressText.value = '';
        programDocValue.value.data = {
          adks: [],
          ...getProgramDoc.value
        };
      }
    };

    function copySharableUrl() {
      const url = programDocValue.value.data.sharable_url.startsWith('https://')
        ? programDocValue.value.data.sharable_url
        : `https://${programDocValue.value.data.sharable_url}`;
      navigator.clipboard.writeText(url);
      store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
        message: 'Copied to clipboard!',
        type: 'success',
        isShowSnackbar: true
      });
    }

    /** Revert version */
    function openModel(version) {
      dialogOpenForRevert.value = true;
      versionValue.value = version;
    }
    const revertVersionData = async () => {
      isSaving.value = true;
      const response = await revertVersion({
        program_id: programDocValue.value.data._id.toString(),
        version: versionValue.value
      });
      programDocValue.value.data = {
        adks: [],
        ...getProgramDoc.value
      };
      if (response) {
        dialogOpenForRevert.value = false;
        tableDialog.value = false;
        isSaving.value = false;
      }
    };

    /** Add stackholders */
    const stakeholders: Ref<Record<string, any>[]> = ref([]);
    const newStakeholderEmail = ref('');
    const addStakeholderError = ref(false);
    const addNewStakeholder = async () => {
      if (newStakeholderEmail.value.length) {
        // check if email is already added
        if (
          stakeholders.value.some(stakeholder => stakeholder.email === newStakeholderEmail.value)
        ) {
          addStakeholderError.value = true;
          return;
        }
        addStakeholderError.value = false;
        const user = await getUserByKeyAndValue({
          key: 'email',
          value: decodeURIComponent(newStakeholderEmail.value)
        });
        // collection.value!('User').findOne({
        //   email: newStakeholderEmail.value
        // });
        const { studentDoc } = props;
        if (!studentDoc.data.stakeholders) {
          studentDoc.data.stakeholders = [];
        }
        if (user) {
          // push user id if user exists
          studentDoc.data.stakeholders.push(user._id);
          stakeholders.value.push(user);
        } else {
          // push email if user doesn't exist
          studentDoc.data.stakeholders.push(newStakeholderEmail.value);
          stakeholders.value.push({ email: newStakeholderEmail.value });
        }
        studentDoc.update();
        newStakeholderEmail.value = '';
      }
    };

    /** Timesheet timer */
    const timer = ref('00:00:00');
    const timerBtnColor = ref('green');
    const isClockIn = ref(false);
    const isClockOut = ref(false);
    const lastClockingTime = ref();
    async function checkStatus() {
      try {
        const API_ENDPOINT = process.env.VUE_APP_TIMESHEET_STATUS;
        const res = await axios.get(
          `${API_ENDPOINT}?student_id=${programDocValue.value?.data?._id.toString()}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        const status = res?.data?.data;
        lastClockingTime.value = res?.data?.time;
        if (status === 'clock_in') {
          isClockOut.value = true;
          isClockIn.value = false;
        }
        if (status === 'clock_out') {
          lastClockingTime.value = undefined;
          isClockIn.value = true;
          isClockOut.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    }
    let checkStatusInterval;
    if (props.userType === 'participant') {
      checkStatusInterval = window.setInterval(() => {
        checkStatus();
        watchEffect(async () => {
          let timerInterval;
          if (isClockIn.value === false && isClockOut.value === true) {
            let diff;
            timerInterval = setInterval(() => {
              let timerValue;
              if (lastClockingTime.value !== undefined) {
                diff = moment(moment().utc()).diff(moment(lastClockingTime.value), 'seconds');
                timerValue = moment.utc(diff * 1000).format('HH:mm:ss');
                if (diff >= 28800) {
                  clearInterval(timerInterval);
                  timer.value = '00:00:00';
                  timerBtnColor.value = 'green';
                } else {
                  timer.value = timerValue;
                  timerBtnColor.value = 'red';
                }
              } else {
                clearInterval(timerInterval);
                timer.value = '00:00:00';
                timerBtnColor.value = 'green';
              }
            }, 1000);
          } else {
            clearInterval(timerInterval);
            timer.value = '00:00:00';
            timerBtnColor.value = 'green';
          }
        });
      }, 15000);

      onUnmounted(() => {
        clearInterval(checkStatusInterval);
      });
    }

    function goToTimesheetActivity() {
      const timesheetIndexValue = props?.programDoc?.data?.adks.findIndex(adk =>
        adk.fields?.find(field => field.name === 'm-timesheet-default')
      );
      if (
        timesheetIndexValue >= 0 &&
        Number(ctx?.root?.$route?.params?.page) !== Number(timesheetIndexValue)
      ) {
        ctx.root.$router.push(
          `/guide/${props.programDoc.data.program_id.toString()}/${timesheetIndexValue}`
        );
      }
    }

    const settingsDialog = ref(false);
    const openSettingsDialog = () => {
      ctx.emit('closeOverlay');
      settingsDialog.value = true;
    };

    const templateNameValue = ref('');
    const programNameValue = ref('');
    const documentType = ref('');

    const saveDocumentDialog = ref(false);
    const isSaveDocument = ref(false);

    const openSaveDocumentDialog = val => {
      if (
        (programDocValue?.value?.data?.tokens !== undefined &&
          programDocValue?.value?.data?.tokens !== null) ||
        programDocValue?.value?.data?.tokensRange
      ) {
        documentType.value = val;
        saveDocumentDialog.value = true;
      } else {
        // templateSettingsDialog.value = true;
        // openTemplateCheckoutView.value = true;
        settingsDialog.value = true;
        openCheckoutView.value = true;
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'You must need to add tokens or tokensRange.',
          type: 'error',
          isShowSnackbar: true
        });
      }
    };
    const allCategoriesExist = computed(() => {
      const { adks } = programDocValue?.value?.data;
      const { categories } = programDocValue?.value?.data;
      return categories?.every(category => {
        return adks?.some(adk => {
          return category.name === adk.category;
        });
      });
    });
    const saveDocument = async data => {
      const { type, nameValue } = data;
      let response;
      const isCategoryExists = allCategoriesExist.value;
      if (isCategoryExists === true) {
        if (type === 'template') {
          templateNameValue.value = nameValue;
          isSaveDocument.value = true;
          response = await updateProgramTemplate({
            payload: {
              isTemplate: true,
              templateName: templateNameValue?.value,
              sharable_url: await getSharableUrlByName({
                name: templateNameValue?.value,
                type: 'template'
              }),
              visibility: programDocValue?.value?.data?.visibility || 'private',
              adks: programDocValue?.value?.data?.adks
            },
            program_schema_id: ctx.root.$route.params.templateId
          });
          if (response?.error?.description === 'Template Already exists with this name!') {
            isSaveDocument.value = false;
            store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
              message: response.error.description,
              type: 'error',
              isShowSnackbar: true
            });
          } else if (response) {
            isSaveDocument.value = false;
            saveDocumentDialog.value = false;
            store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
              message: 'Template Saved successfully',
              type: 'Success',
              isShowSnackbar: true
            });
            await props.fetchProgram();
          }
          templateNameValue.value = '';
        } else {
          programNameValue.value = nameValue;
          isSaveDocument.value = true;
          response = await cloneProgram({
            program_template_id: ctx.root.$route.params.templateId,
            programName: programNameValue?.value,
            sharable_url: await getSharableUrlByName({
              name: programNameValue?.value,
              type: 'program'
            })
          });
          if (response?.error?.description === 'Program Already exists with this name!') {
            isSaveDocument.value = false;
            store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
              message: response.error.description,
              type: 'error',
              isShowSnackbar: true
            });
          } else if (response) {
            isSaveDocument.value = false;
            ctx.root.$router.push({
              name: 'guide',
              params: {
                programId: response._id.toString(),
                page: 0
              }
            });
            await props.fetchProgram();
          }
          programNameValue.value = '';
        }
      } else {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: 'category should contain atleast one adk or delete the category to save',
          type: 'error',
          isShowSnackbar: true
        });
      }
    };

    onMounted(() => {
      isSetupWizardOpen.value = true;
    });

    const deleteItem = async () => {
      deleting.value = true;
      const adkItem = itemTobeDeleted.value;
      const { name } = adkItem;
      const cat = adkItem?.category;
      let collection_name = props.isTemplate ? 'ProgramSchema' : 'Program';
      if (props?.isDraft) {
        collection_name = 'ProgramRevision';
      }
      const templateOrProgramId = programIdValue?.value?.toString();
      const result = await deleteAdkOrCategory({
        templateOrProgramId,
        name,
        category: cat,
        type: deletionType.value,
        collection_name,
        position: adkItem?.position
      });
      if (!result?.data?.error) {
        await props.fetchProgram();
        deleteDialogue.value = false;
        deleting.value = false;
        itemTobeDeleted.value = '';
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: result.message,
          type: 'success',
          isShowSnackbar: true
        });
      } else {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: result.data.error.description,
          type: 'error',
          isShowSnackbar: true
        });
        deleting.value = false;
      }
    };

    const editItem = async () => {
      // category, collection_name, type, name, templateOrProgramId
      deleting.value = true;
      const cat = categoryName?.value?.toLowerCase();
      const adkItem = itemTobeDeleted.value;
      const { name } = adkItem;
      const collection_name = props.isTemplate ? 'ProgramSchema' : 'Program';
      const templateOrProgramId = programIdValue?.value?.toString();
      const result = await editCategory({
        programId: templateOrProgramId,
        categoryName: cat,
        oldCategory: name,
        documentType: collection_name,
        icon: selectedItem.value
      });
      if (!result?.data?.error) {
        await props.fetchProgram();
        deleteDialogue.value = false;
        deleting.value = false;
        selectedItem.value = '';
        categoryName.value = '';
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: result.message,
          type:
            result.message === 'Category already exists or no changes were made.'
              ? 'error'
              : 'success',
          isShowSnackbar: true
        });
      } else {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: result.data.error.description,
          type: 'error',
          isShowSnackbar: true
        });
        deleting.value = false;
      }
    };

    const activitySettingsDialog = ref(false);
    const adkData = computed(() => {
      const { adks } = programDocValue?.value?.data;
      const { name, setupRequired, icon } = adks[currentIndex.value];
      const data = {
        name,
        icon,
        setupRequired
      };
      return data;
    });

    const permissions = computed({
      get: () => localStorage?.getItem('permissions')?.split(',') || [],
      set: newVal => {
        ctx.emit('permissions', newVal);
      }
    });

    const openPublishDialog = () => {
      // const mapedAdks = programDocValue?.value?.data?.adks?.map(adk => adk?.name);
      // if (!isPublished.value) {
      //   if (
      //     programDocValue?.value?.data?.tokens === undefined ||
      //     programDocValue?.value?.data?.tokens === null
      //   ) {
      //     // programSettingsDialog.value = true;
      //     // openProgramCheckoutView.value = true;
      //     settingsDialog.value = true;
      //     openCheckoutView.value = true;
      //     store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
      //       message: 'You must need to add tokens.',
      //       type: 'error',
      //       isShowSnackbar: true
      //     });
      //   } else if (
      //     programDocValue?.value?.data?.isUnsponsoredProgram &&
      //     !mapedAdks?.includes(programDocValue?.value?.data?.unsponsoredAdk)
      //   ) {
      //     settingsDialog.value = true;
      //     openCheckoutView.value = true;
      //     store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
      //       message: 'You must need set unsponsored activity.',
      //       type: 'error',
      //       isShowSnackbar: true
      //     });
      //   } else {
      //     dialog.value = true;
      //   }
      // } else {
      //   dialog.value = true;
      // }
      dialog.value = true;
    };

    const publishChanges = async () => {
      isSavingChanges.value = true;
      await deleteDraft({ program_id: programDocValue?.value?.data?._id?.toString() });
      // await publishProgramRevision(programDocValue.value.data);
      setTimeout(() => {
        isSavingChanges.value = false;
        isSaveChanges.value = false;
      }, 2000);
    };

    const isDisplayPublicDialog = ref(false);

    return {
      items,
      selectedItem,
      categoryName,
      categoryIcon,
      showAdkAddIcon,
      schemaIndex,
      openActivitySchemaDialog,
      hover,
      hoverIndex,
      isOptions,
      editTemplate,
      useToken,
      orgMsg,
      phoneNumber,
      formattedNumber,
      inviteType,
      expand,
      closeBar,
      activeStep,
      dialog,
      getHelpDialog: ref(false),
      dialogManageAccess,
      openManageAccess,
      dialog2,
      unlockedStep,
      isPublished,
      changeStep,
      shareAccess: ['Monitor', 'Remove'],
      manageAccess: ['Employer', 'Remove'],
      manageInput: ['Email', 'Phone'],
      toggleCategory,
      showPreInternshipSteps,
      validation,
      showInternshipSteps,
      projectItems,
      internshipItems,
      StudentStatus,
      programDocValue,
      studentOfferStatus: getStudentStatus(programDocValue.value.data),
      OperationMode,
      getIconColor,
      getCompletedIcon,
      newOrganizerEmail,
      openGuideBar,
      titleValue,
      changePublishStatus,
      isDraggable,
      emitMolecule,
      isSetupAdksCompleted,
      programRevisionData,
      getProgramRevisionData,
      dialogOpen,
      isLoading,
      // showPublishConfirmation,
      publishProgramRevisionData,
      tableDialog,
      revertVersionData,
      progressText,
      isSaving,
      dialogOpenForRevert,
      versionValue,
      openModel,
      momentFormat,
      formatDate,
      stakeholders,
      newStakeholderEmail,
      addStakeholderError,
      addNewStakeholder,
      isShareAccess,
      openShareAccess,
      leaveProgram,
      programIdValue,
      timesheetIndex,
      timesheetFieldIndex,
      timer,
      timerBtnColor,
      isClockIn,
      isClockOut,
      lastClockingTime,
      checkStatus,
      checkStatusInterval,
      goToTimesheetActivity,
      copySharableUrl,
      isSavingData,
      isSaveData,
      headers,
      getAdkCompletion,
      isSaveProgressValue,
      disableSaveProgress,
      isDeliverableAvailable,
      openSettingsDialog,
      openActivitySchema,
      saveDocument,
      saveDocumentDialog,
      openSaveDocumentDialog,
      documentType,
      templateNameValue,
      programNameValue,
      isSaveDocument,
      activitySettingsDialog,
      adkData,
      permissions,
      isCategoryDialogue,
      addNewCategory,
      categories,
      isAddCategory,
      activeCategory,
      hiddenCategories,
      deleteDialogue,
      deleteItem,
      editItem,
      itemTobeDeleted,
      deleting,
      deletionType,
      requiredAdks,
      currentIndex,
      isSetupWizardOpen,
      dialogueType,
      openPublishDialog,
      openProgramCheckoutView,
      openTemplateCheckoutView,
      openCheckoutView,
      settingsDialog,
      isSaveChanges,
      sureLeaveProgram,
      isSavingChanges,
      publishChanges,
      allCategoriesExist,
      isDisplayPublicDialog
    };
  }
});
